<template>
	<div class="message_page">
		<div class="message_tab">
			<div class="message_nav">
				<div @click="tabClick(0)" :class="[current == 0?'current':'']">
					<p>{{discussNum}}条评论</p>
				</div>
				<div @click="tabClick(1)" :class="[current == 1?'current':'']">
					<p>{{likeNum}}条点赞</p>
				</div>
			</div>
			<div class="discuss_content" v-if="current==0">
				<div class="discuss_list">
					<div class="discuss_item" v-for="(item,index) in discussList" :key="index">
						<div>
							<img :src="item.pictureUrl" alt="">
							<div>
								<h4>{{item.userNameAndPosition}}</h4>


								<p v-if="item.type == 1"><span>回复了我的评论 “{{ item.content }}”</span></p>
								<p v-if="item.type == 0"><span>评论了我的贴子《{{ item.content }}》</span></p>
								<p style="margin-top:5px">{{item.forumManagementComments}}</p>
							</div>
						</div>
						<a @click="toDetails(item.forumId)"><svg-icon icon-class="arrow"></svg-icon></a>
					</div>
				</div>
				<div class="noData" v-if="this.discussList.length==0">
					<img src="@/assets/PC/profile/listNone.png" alt="" />
				</div>	
				<div class="discuss_pagination" v-if="discussNum>pageSize">
					<el-pagination background layout="pager, next" next-text="下一页" :page-size="pageSize" :pager-count="pageCount" 
					:total="discussNum" @current-change="discussPageClick" :current-page="discussParams.page" @next-click="discussPageClick">
					</el-pagination>
				</div>
				<a class="onBottomLoad_btn" @click="getDiscuss" v-if="!noDiscuss">点击加载</a>
			</div>
			<div class="like_content" v-else>
				<div class="like_list">
						<div class="like_item" v-for="(item,index) in likeList" :key="index">
							<div>
								<img :src="item.pictureUrl" alt="">
								<div>
									<h4>{{ item.userNameAndPosition }}</h4>
									<p v-if="item.type == 1"><span>赞了我的评论 “{{ item.commentInfo.forumManagementComments }}”</span></p>
									<p v-if="item.type == 0"><span>赞了我的贴子《{{ item.forumInfo.title }}》</span></p>
									<p style="margin-top:5px">{{item.forumManagementComments}}</p>
									<p>{{ item.likeDateString }}</p>
								</div>
							</div>
						</div>
				</div>
				<div class="noData" v-if="this.likeList.length==0">
					<img src="@/assets/PC/profile/listNone.png" alt="" />
				</div>	
				<div class="like_pagination" v-if="likeNum>pageSize">
					<el-pagination background layout="pager, next" next-text="下一页" :page-size="pageSize" :pager-count="pageCount" 
					:total="likeNum" @current-change="likePageClick" :current-page="likeParams.page" @next-click="likePageClick">
					</el-pagination>
				</div>
				<a class="onBottomLoad_btn" @click="getLike" v-if="!noLike">点击加载</a>
			</div>
		</div>
	</div>
</template>

<script>
	import Headers from '@/components/header.vue';
	import * as forum from '../../api/forum.js';
	import { dataState } from "@/api/all.js";
	
	export default {
		name: "message",
		components: {
			Headers
		},
		props: ['searchVal','isSearch'],
		watch: {
			screenWidth(val) {
				this.screenWidth = val
				if (this.screenWidth < 768) {
					this.isPc = false
				} else {
					this.isPc = true
				}
			},
			searchVal(val) {
				this.searchTitle = val
			},
			isSearch(val) {
				if(val){
					this.search()
				}
			}
		},
		created(){
			if (this.screenWidth < 768) {
				this.isPc = false;
			} else {
				this.isPc = true;
			}
			
			this.$emit('change', 5, '我的消息')
			this.initial()
		},
		data() {
			return {
				current: 0,
				searchTitle: '',
				pageSize: 5,
				pageCount: 5,
				// 评论
				noDiscuss: true,
				discussRecordCount: 0,
				discussNum: 0,
				discussParams: {
					"page": 1,
					"limit": 5,
					"title": ''
				},
				// 点赞
				noLike: true,
				likeRecordCount: 0,
				likeNum: 0,
				likeParams: {
					"page": 1,
					"limit": 5,
					"title": ''
				},
				isInitial_1: true,
				isInitial_2: true,
				
				isPc: true,
				screenWidth: document.body.clientWidth,
				discussList: [],
				likeList: [],
			};
		},
		mounted() {
			// 数据统计
    const data ={
      "visitType": 1,
      "gid": sessionStorage.getItem('gid') ? sessionStorage.getItem('gid') : '',
      "url": window.location.href,
      "pageUrl": "Message",
      "relationId": ""
    }
    dataState(data).then(res => {
      sessionStorage.setItem('gid',res.data);
    })
		},
		methods: {
			initial(){
				this.getDiscuss()
				this.getLike()
				this.isInitial_1 = false
				this.isInitial_2 = false
			},
			toDetails(id){
				this.$router.push('/home/forumDetails?aim=discuss&id='+id)
			},
			discussPageClick(page){
				this.discussParams.page = page
				this.getDiscuss()
			},
			likePageClick(page){
				this.likeParams.page = page
				this.getLike()
			},
			search(){
				if(this.current == 0){
					this.isInitial_1 = true
					this.discussParams.page = 1
					this.discussParams.title = this.searchTitle
					this.getDiscuss()
				}else{
					this.isInitial_2 = true
					this.likeParams.page = 1
					this.likeParams.title = this.searchTitle
					this.getLike()
				}
			},
			getDiscuss(){
				if(!this.isPc && !this.isInitial_1){
					this.discussParams.page ++
				}
				forum.getUserComment(this.discussParams).then(res => {
					if(res.status == 1){
						if(!this.isPc){
							this.discussList.push(...res.data)
							if(this.discussList.length == res.recordCount){
								this.noDiscuss = true
							}else{
								this.noDiscuss = false
							}
						}else{
							this.discussList = res.data
						}
						this.isInitial_1 = false
						this.discussNum = res.recordCount
					}
				})
			},
			getLike(){
				if(!this.isPc && !this.isInitial_2){
					this.likeParams.page ++
				}
				
				forum.getUserLike(this.likeParams).then(res => {
					if(res.status == 1){
						if(!this.isPc){
							this.likeList.push(...res.data)
							if(this.likeList.length == res.recordCount){
								this.noLike = true
							}else{
								this.noLike = false
							}
						}else{
							this.likeList = res.data
						}
						this.isInitial_2 = false
						this.likeNum = res.recordCount
						
					}
				})
			},
			tabClick(index){
				this.current = index
			}
		}
	};
</script>

<style lang="scss" scoped>
	$mobil_base: 750;

	@function vw($true) {
		@return ($true / $mobil_base) * 100vw;
	}

	.message_page {
		margin-bottom: 100px;
		.message_tab {
			border: 1px solid #E6E6E6;

			.message_nav {
				display: flex;

				div {
					width: 50%;
					height: 48px;
					line-height: 48px;
					text-align: center;
					background: #EDEDED;
					cursor: pointer;
					p {
						font-size: 16px;
						color: #363D42;
						margin: 0;
					}

					&.current {
						background: #fff;
						position: relative;
						p {
							color: #6B18A8;
							font-weight: 700;
						}

						p:after {
							content: '';
							display: block;
							position: absolute;
							bottom: 0;
							left: 50%;
							transform: translateX(-50%);
							width: 108px;
							height: 2px;
							background: #6B18A8;
						}
					}
				}
			}
			
			.discuss_content{
				.discuss_list {
					padding: 0 40px 0;
				
					.discuss_item {
						padding: 40px 0;
						border-bottom: 1px solid #EDEDED;
						display: flex;
						justify-content: space-between;
						align-items: center;
						&>a{
							cursor: pointer;
						}
						
						&>div {
							display: flex;
							justify-content: flex-start;
							align-items: center;
				
							img {
								width: 56px;
								border-radius: 50%;
								margin: 0 18px 0 0;
							}
				
							div {
								h4 {
									font-size: 16px;
									color: #363D42;
								}
								p {
									font-size: 16px;
									color: #738294;
									line-height: 26px;
									overflow: hidden;
									text-overflow: ellipsis;
									display: -webkit-box;
									-webkit-box-orient: vertical;
									-webkit-line-clamp: 2;
								}
							}
						}
					}
				}
				.discuss_pagination {
					padding: 0 40px 0 0;
					margin: 80px 0 40px 0;
				
					.el-pagination {
						display: flex;
						justify-content: flex-end;
				
						/deep/ .el-pager li {
							width: 45px;
							height: 45px;
							font-size: 16px;
							color: #363D42;
							text-align: center;
							line-height: 45px;
						}
				
						/deep/ .el-pager li.active {
							color: #fff;
							background-color: #6B18A8;
						}
				
						/deep/ .btn-next {
							width: 95px;
							height: 45px;
							color: #fff;
							background-color: #6B18A8;
						}
					}
				}
				
				.onBottomLoad_btn {
					display: none;
				}
			}
			
			.like_content{
				.like_list {
					padding: 0 40px 0;
				
					.like_item {
						padding: 40px 0;
						border-bottom: 1px solid #EDEDED;
				
						&>div:first-of-type{
							display: flex;
							align-items: center;
							img{
								width: 56px;
								margin-right: 18px;
							}
							div{
								h4{
									font-size: 16px;
									color: #363D42;
								}
								p{
									font-size: 16px;
									color: #738294;
									line-height: 26px
								}
							}
						}
						
						&>div:nth-of-type(2){
							display: flex;
							align-items: center;
							margin-bottom: 24px;
							span{
								font-size: 16px;
								color: #363D42;
							}
							svg{
								width: 9px;
								height: 24px;
								margin-left: 12px;
							}
						}
						
						&>div:nth-of-type(3){
							padding: 24px 36px 24px 24px;
							background: #F6F8FA;
							cursor: pointer;
							
							h5{
								font-size: 16px;
								color: #363D42;
								margin: 0 0 10px;
							}
							p{
								font-size: 16px;
								color: #738294;
								line-height: 26px;
								margin: 0;
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 2;
							}
						}
					}
				}
				.like_pagination {
					padding: 0 40px 0 0;
					margin: 80px 0 40px 0;
				
					.el-pagination {
						display: flex;
						justify-content: flex-end;
				
						/deep/ .el-pager li {
							width: 45px;
							height: 45px;
							font-size: 16px;
							color: #363D42;
							text-align: center;
							line-height: 45px;
						}
				
						/deep/ .el-pager li.active {
							color: #fff;
							background-color: #6B18A8;
						}
				
						/deep/ .btn-next {
							width: 95px;
							height: 45px;
							color: #fff;
							background-color: #6B18A8;
						}
					}
				}
				
				.onBottomLoad_btn {
					display: none;
				}
			}
		}
	}

	@media (max-width: 750px) {
		.message_page {
			margin-bottom: vw(80);
			.message_tab {margin-top: vw(40);border: vw(1) solid #E6E6E6;}
		}

	}


	@media (max-width: 750px) {
		.message_page {
			.message_tab {
				
				.message_nav {
				
					div {
						height: vw(68);
						line-height: vw(68);
						p {
							font-size: vw(28);
						}
				
						&.current {
							p:after {
								width: vw(176);
								height: vw(2);
							}
						}
					}
				}
				.discuss_content{
					.discuss_list {
						padding: 0 vw(40) 0;
					
						.discuss_item {
							padding: vw(40) 0;
							border-bottom: vw(1) solid #EDEDED;
					
							&>div {
								align-items: flex-start;
								img {
									width: vw(76);
									margin: 0 vw(15) 0 0;
								}
					
								div {
									h4 {
										font-size: vw(24);
										margin: 0 0 vw(24) 0;
									}
					
									p {
										font-size: vw(24);
										line-height: vw(40);
									}
								}
							}
							svg{
								display: none;
							}
						}
					}
					.discuss_pagination{
						display: none;
					}
					
					.onBottomLoad_btn {
						display: block;
						width: vw(166);
						height: vw(52);
						color: #738294;
						line-height: vw(52);
						background: #E6E6E6;
						font-size: vw(20);
						text-align: center;
						margin: 0 auto vw(40);
					}
				}
				.like_content{
					.like_list {
						padding: 0 vw(40) 0;
					
						.like_item {
							padding: vw(40) 0;
							border-bottom: vw(1) solid #EDEDED;
					
							&>div:first-of-type{
								margin-bottom: vw(30);
								img{
									width: vw(76);
									margin-right: vw(15);
								}
								div{
									h4{
										font-size: vw(24);
									}
									p{
										font-size: vw(24);
										margin-top: vw(12);
									}
								}
							}
							
							&>div:nth-of-type(2){
								margin-bottom: vw(24);
								span{
									font-size: vw(24);
								}
								svg{
									width: vw(9);
									height: vw(24);
									margin-left: vw(12);
								}
							}
							
							&>div:nth-of-type(3){
								padding: vw(24) vw(36) vw(24) vw(24);
								
								h5{
									font-size: vw(24);
									margin: 0 0 vw(10);
								}
								p{
									font-size: vw(24);
									line-height: vw(40);
									padding-bottom: vw(4);
								}
							}
						}
					}
					.like_pagination{
						display: none;
					}
					
					.onBottomLoad_btn {
						display: block;
						width: vw(166);
						height: vw(52);
						color: #738294;
						line-height: vw(52);
						background: #E6E6E6;
						font-size: vw(20);
						text-align: center;
						margin: 0 auto vw(40);
					}
				}
			}
		}
	}
</style>
